<template>
  <v-app>
    <navbar></navbar>

    <v-main>
      <router-view></router-view>
    </v-main>

    <bsfooter></bsfooter>
  </v-app>
</template>

<script>
import navbar from './components/Navbar.vue';
import bsfooter from './components/BsFooter.vue';

export default {
  name: 'App',
  components: {
    navbar,
    bsfooter: bsfooter,
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss" scoped>
  img{
    width: 95px;
  }
</style>
