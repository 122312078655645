<template>
  <div class="home">
    <div class="mask"></div>
    <div class="homeheader">
      <h1 class="white--text">We Help You find The Best Solution</h1>
      <p class="white--text">
        Best solution Recruiting Ltd. is dedicated to providing a strictly
        <br/>confidential and professional recruitment and marketing strategy service to our clients.
      </p>

      <v-form class="search">
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <v-text-field label="Search Keywords" solo></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-select :items='specialisms' label="Choose Specialism" solo></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="2">
              <v-select :items='locations' label="Choose Location" solo></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-btn class="findBtn" @click="uselessClick()">
                Find Job
              </v-btn>
            </v-col>

          </v-row>
        </v-container>
      </v-form>

      <v-img class="bg" :src="bgUrl" eager></v-img>
    </div>

    <v-container class="features">
      <h2 class="featureTitle">We offer a complete range of features</h2>

      <v-row>
        <v-col v-for="feature in features" :key="feature.index" cols="4">

          <v-icon large color="blue lighten-2">
            {{ feature.icon }}
          </v-icon>
          
          <div class="my-5 font-weight-bold grey--text text--darken-3">
            {{ feature.title }}
          </div>

          <div class="grey--text text--darken-2">
            {{ feature.content }}
          </div>

        </v-col>
      </v-row>

    </v-container>

    <v-card v-for="job in jobs" :key="job.index"  class="mx-10 my-10" elevation="3" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-overline mb-4">
            {{ job.companyname }}
          </div>
          <v-list-item-title class="text-h5 mb-1">
            {{ job.jobname }}
          </v-list-item-title>
          <v-list-item-subtitle>
            Job Description: {{ job.description }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <!-- <v-img class="joblogo" src="../assets/companylogo.png" eager></v-img> -->
        <v-img class="joblogo" :src="job.logoUrl" eager></v-img>
      </v-list-item>
    </v-card>
    
  </div>
</template>

<script>

  export default {
    name: 'Home',

    data() {
      return {
        bgUrl: require('../static/img/bg.jpg'),
        jobs:[
          {companyname:'Company Name', jobname:'Job Name', description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor, temporibus alias totam adipisci rem tempore sequi eum consectetur corporis...', logoUrl:require('../static/img/companylogo.png')},
          {companyname:'Company Name', jobname:'Job Name', description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor, temporibus alias totam adipisci rem tempore sequi eum consectetur corporis...', logoUrl:require('../static/img/companylogo.png')},
          {companyname:'Company Name', jobname:'Job Name', description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor, temporibus alias totam adipisci rem tempore sequi eum consectetur corporis...', logoUrl:require('../static/img/companylogo.png')},
          {companyname:'Company Name', jobname:'Job Name', description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor, temporibus alias totam adipisci rem tempore sequi eum consectetur corporis...', logoUrl:require('../static/img/companylogo.png')},
          {companyname:'Company Name', jobname:'Job Name', description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor, temporibus alias totam adipisci rem tempore sequi eum consectetur corporis...', logoUrl:require('../static/img/companylogo.png')},
          {companyname:'Company Name', jobname:'Job Name', description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor, temporibus alias totam adipisci rem tempore sequi eum consectetur corporis...', logoUrl:require('../static/img/companylogo.png')}
        ],
        specialisms:['a','b','b','c','d','e','f'],
        locations:['a','b','b','c','d','e','f'],
        isShow: false,
        features:[
          {icon: 'mdi-cart', title: 'Find your Job', content:'Eos tota dicunt democritum no. Has natum gubergren ne. Est viris soleat sadipscing cu. Legere epicuri insolens eu nec, dicit virtute urbanitas id nam, qui in habeo semper eligendi.' },
          {icon:'mdi-shield-account' , title:'Info Protection' , content:'Ne nam phaedrum consequat, adhuc aliquid ea pri, eum eligendi incorrupte referrentur in. Vix ad senserit salutandi argumentum. Ei eam definiebas reformidans, exerci persecuti no ius.' },
          {icon:'mdi-tag' , title:'Fair Price' , content:'Assum suavitate ea vel, vero erat doming cu cum. Zril ornatus sea cu. Pro ex integre pertinax. Cu cum harum paulo legendos, mei et quod enim mnesarchum, habeo affert laoreet sea ei.' }
        ]
      }
    },
    methods: {
      uselessClick() {
        alert('Oops! Function in Progress...')
      }
    },
  }
</script>

<style lang="scss" scoped>

$fontColor: rgb(19, 54, 117);

.mask{
  width: 100%;
  height: 45rem;
  background-color: #000;
  opacity: 25%;
  position: absolute;
  z-index: 1;
}

.homeheader{
  width: 100%;
  height: 45rem;
  background-color: #aaa;
  .bg{
    position: absolute;
    top: 0;
    height: 45rem;
  }
  p,h1{
    text-align: left;
    align-items: center;
    position: relative;
    padding-left: 20rem;
    z-index: 2;
    text-shadow: 3px 3px 3px $fontColor;
  }
  h1{
    padding-top: 12rem;
    padding-bottom: 3rem;
    font-size: 2.5rem;
  }
  .search{
    position: relative;
    z-index: 2;
    padding-left: 19rem;
    padding-top: 4rem;
    .findBtn{
      height: 48px;
      width: 120px;
      font-weight: 700;
      font-size: 15px;
      background-color: #e27c1d;
      color: #fff;
    }
  }
}

.features{
  text-align: center;
  margin-bottom: 10rem;
  h2{
    color: $fontColor;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}

.joblogo{
  max-width: 16rem;
}
</style>
