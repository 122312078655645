<template>
  <v-app-bar app absolute color="white" elevate-on-scroll>

    <img src="../assets/companylogo.png" alt="copmpanylogo">

    <v-spacer></v-spacer>

    <div class="black--text mx-2">
      <v-btn text href="/">
        Home
      </v-btn>
    </div>
    
    <div class="black--text mx-2">
      <v-btn text href="/about">
        About
      </v-btn>
    </div>

    <div class="black--text mx-2">
      <v-btn text href="/" disabled>
        Price
      </v-btn>
    </div>

    <div class="black--text mx-2">
      <v-btn text href="/" disabled>
        Job Listing
      </v-btn>
    </div>

    <v-menu transition="scroll-y-transition">
      <template v-slot:activator="{ on, attrs }">
        <div class="mx-2">
          <v-btn class="my-1" fab light small elevation="0" v-bind="attrs" v-on="on">
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </div>
      </template>

      <v-list class="droplist">
        <v-list-item v-for="(item, index) in loginList" :key="index">
          <v-btn class="listBtn" plain :href="item.href">
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
    
  </v-app-bar>
</template>

<script>

export default {
  data: () => ({
    loginList:[
      {title: 'Log In', href: '/'},
      {title: 'Sign In', href: '/'}
    ],
    expand: false
  }),
};
</script>

<style lang="scss" scoped>
  img{
    width: 95px;
  }
  .droplist{
    padding: 0;
  }
  .v-menu__content{
    top: 56px !important;
  }
  .v-list-item{
    padding: 0;
  }
  .listBtn{
    width: 70px !important;
    // padding: 0 !important;
  }
  .v-list-item__title{
    font-size: 5px;
  }
</style>