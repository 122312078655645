<template>
  <v-footer color="primary" padless>
    <v-row justify="center" no-gutters>
      <v-btn v-for="link in links" :key="link.id" color="white" text rounded
        class="my-2" :href="link.href">
        {{ link.name }}
      </v-btn>

      <v-col class="grey--text text--lighten-1 pt-0 pb-5 text-center caption" cols="10">
        {{ message }}
      </v-col>

      <v-col cols="12">
        <v-divider dark></v-divider>
      </v-col>
      
      <v-col class="primary py-4 text-center white--text" cols="12">
        {{ new Date().getFullYear() }} — <strong>BestSolution</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      links: [
        {id: 0, name: 'Home', href: '/'},
        {id: 1, name: 'About', href: '/about'},
        {id: 2, name: 'Price', href: '/'},
        {id: 3, name: 'Job Listing', href: '/'},
        {id: 4, name: 'Privacy', href: '/'}
      ],
      message: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.Sed dolore neque sequi perferendis impedit minus necessitatibus vero labore, sit aperiam voluptate quasi ratione modi ad ipsa, consectetur dolor, harum unde.'
    }
  },
}
</script>
